var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "vs-popup",
        {
          ref: "popup-handling-history",
          attrs: {
            title: _vm.$t("appeal.handling.history_"),
            active: _vm.visible,
            id: "popup-handling-history",
            fullscreen: true,
          },
          on: {
            "update:active": function ($event) {
              _vm.visible = $event
            },
            close: _vm.closePopup,
          },
        },
        [
          _c(
            "vs-table",
            {
              attrs: {
                data: _vm.listHistory,
                sst: true,
                "current-sort-type": "asc",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ data }) {
                    return _vm._l(data, function (tr, i) {
                      return _c(
                        "vs-tr",
                        { key: data[i] },
                        [
                          _c("vs-td", { staticClass: "break-all p-1 pl-4" }, [
                            _c("div", { staticClass: "w-full text-sm" }, [
                              _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$utils.format.dateWithFormat(
                                        _vm._.get(tr, "created_at"),
                                        "DD/MM/YYYY - kk:mm:ss"
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                            ]),
                          ]),
                          _c("vs-td", {}, [
                            _c("span", [
                              _vm._v(_vm._s(_vm._.get(tr, "status_str"))),
                            ]),
                          ]),
                          _c("vs-td", {}, [
                            _c("div", { staticClass: "w-full" }, [
                              _c("div", {
                                directives: [
                                  {
                                    name: "html-safe",
                                    rawName: "v-html-safe",
                                    value: _vm._.get(tr, "description"),
                                    expression: "_.get(tr, 'description')",
                                  },
                                ],
                              }),
                            ]),
                          ]),
                        ],
                        1
                      )
                    })
                  },
                },
              ]),
            },
            [
              _c(
                "template",
                { slot: "thead" },
                [
                  _c("vs-th", { attrs: { width: "20%" } }, [
                    _vm._v(_vm._s(_vm.$t("data-0"))),
                  ]),
                  _c("vs-th", { attrs: { width: "15%" } }, [
                    _vm._v(_vm._s(_vm.$t("appeal.status"))),
                  ]),
                  _c("vs-th", [
                    _vm._v(_vm._s(_vm.$t("appeal.handling.label"))),
                  ]),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }